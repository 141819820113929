import React from 'react'
import BigCardList from '../bigCards/BigCardList'
import SideMenu from '../sideMenu/SideMenu'
import Smallcards from "../../components/smallcards/smallcards";
import AdsMidBottom from '../ads/AdsMidBottom';
import AdsMidBottom2 from '../ads/AdsMidBottom2';


const CardsAndMenu = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-[18px] mt-6 mb-6">
      <div className='col-span-2'>
      {/* < AdsMidBottom /> <br /> */}
        <BigCardList /></div>
        <div className=" lg:block">
          {/* <AdsMidBottom2 /> <br /> */}
          <SideMenu />
        </div>
      </div>
  )
}

export default CardsAndMenu