import "./ref.css";
import { useEffect } from "react";
import sigortaGundem from "./sigorta-gundem-logo.png";
import psmMagazin from "./psm-logo.png";
import akilliYasam from "./akilli-yasam-logo.png";
import psmAwards from "./psm-awards-logo.png";
import smartiAwards from "./smarti-awards-logo.png";
import ekonomimanset from "./../../assets/ekonomi-manset-logo.png";
import wspark from "./w-spark-logo.png";
import { Link } from "react-router-dom";

const Ref = () => {
  useEffect(() => {
    const scrollers = document.querySelectorAll(".scroller");

    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        scroller.setAttribute("data-animated", true);

        // Mobil görünüm için hız değiştirme
        if (window.matchMedia("(max-width: 768px)").matches) {
          scroller.setAttribute("data-speed", "fast");
        } else {
          scroller.setAttribute("data-speed", "slow");
        }

        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);

        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []);

  return (
    <div className="horizontal-content">
      <div className="scroller" data-direction="left" data-speed="slow">
        <div className="scroller__inner">
          <a target="_blank" href="https://ekonomimanset.com/">
            <img src={ekonomimanset} alt="" />
          </a>
          <a target="_blank" href="https://www.psmmag.com/">
            <img src={psmMagazin} alt="" />
          </a>
          <a target="_blank" href="https://sigortagundem.com/">
            <img src={sigortaGundem} alt="" />
          </a>
          <a target="_blank" href="https://www.akilliyasamdergisi.com/">
            <img src={akilliYasam} alt="" />
          </a>
          <a target="_blank" href="https://psmawards.com/">
            <img src={psmAwards} alt="" />
          </a>
          <a target="_blank" href="https://smartiawards.com/">
            <img src={smartiAwards} alt="" />
          </a>

          <a target="_blank" href="https://wspark.com.tr/">
            <img src={wspark} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Ref;
