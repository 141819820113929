import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "./adsmid.css";

const AdsMidBottom = () => {
  const [ads, setAds] = useState([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await axios.get('https://api.ekonomimanset.com/api/ads');
        const data = response.data;

        const filteredAds = data.filter(ad => 
          ad.title.includes("Sıcak Haberler ÜSTÜ BANNER") && ad.isActive === true
        );

        setAds(filteredAds);

        for (const ad of filteredAds) {
          await axios.post(`https://api.ekonomimanset.com/api/ads/${ad._id}/hit`);
        }
      } catch (error) {
        console.error("Reklamlar çekilemedi:", error);
      }
    };

    fetchAds();
  }, []);

  const handleAdClick = async (ad) => {
    if (ad && ad._id) {
      try {
        await axios.post(`https://api.ekonomimanset.com/api/ads/${ad._id}/hit`);
        window.open(ad.link, '_blank');
      } catch (error) {
        console.error("Error incrementing hit count:", error);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [ads]);

  const renderAd = (ad) => {
    if (ad.imageURL && ad.imageURL.includes('doubleclick.net')) {
      return (
        <iframe
          src={ad.link}
          title={ad.title}
          width="970"
          height="250"
          frameBorder="0"
          scrolling="no"
          style={{ border: 'none' }}
        ></iframe>
      );
    } else {
      return (
        <img
          src={ad.imageURL}
          alt={ad.title}
          style={{ width: '970px', height: '250px', cursor: 'pointer' }}
          onClick={() => handleAdClick(ad)}
        />
      );
    }
  };

  return (
    <div className='mid-bottom-ads-container'>
      {ads.length > 0 && (
        <div className="advertisement-x">
          {renderAd(ads[currentAdIndex])}
        </div>
      )}
    </div>
  );
};

export default AdsMidBottom;
