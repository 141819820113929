import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import "./searchpage.css";
import SideMenu from "../../components/sideMenu/SideMenu";

const SearchPage = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visibleItems, setVisibleItems] = useState(18);
  const [query, setQuery] = useState("");

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get("q");
    setQuery(searchQuery);

    const fetchSearchResults = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(`https://api.ekonomimanset.com/search`, {
          params: { query: searchQuery },
        });
        const sortedResults = response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setSearchResults(sortedResults);
      } catch (error) {
        console.error("Search error:", error);
        setError("Arama sırasında bir hata oluştu. Lütfen tekrar deneyin.");
      } finally {
        setIsLoading(false);
      }
    };
    
    if (searchQuery) {
      fetchSearchResults();
    }
  }, [location.search]);

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 9);
  };

  return (
    <div className="search-page-container">
      <div className="search-page-top-container md:grid grid-cols-11 md:px-60">
        <div className="search-page-top-left-container col-span-7 md:pr-[30px]">
          <h1 className="search-page-title1">"{query}" için arama sonuçları</h1>

          {isLoading ? (
            <p>Arama sonuçları yükleniyor...</p>
          ) : error ? (
            <p className="error-message">{error}</p>
          ) : searchResults.length === 0 ? (
            <p>Arama sonucu bulunamadı.</p>
          ) : (
            <>
              <div className="search-news-grid-container">
                <div className="search-news-grid">
                  {searchResults.slice(0, visibleItems).map((item) => (
                    <div key={item._id} className="search-news-item">
                      <Link to={`/${item.slug}`} target="_blank">
                        <img src={item.image} alt={item.title} />
                        <div className="search-news-content">
                          <h3 className="title">{item.title}</h3>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
              <div className="text-center">
                {visibleItems < searchResults.length && (
                  <button
                    onClick={showMoreItems}
                    className="load-more-button search-page-load-more-button"
                  >
                    Daha Fazla Sonuç Yükle
                  </button>
                )}
              </div>
            </>
          )}
        </div>

        <div className="search-page-top-right-container col-span-4 md:pl-[30px]">
          <SideMenu />
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
